#landing-wrapper {
    align-self: center;
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 110px;
    margin-top: 100px;
    margin-bottom: 100px;
}

@media (max-height: 700px) {
    #landing-wrapper {
        margin-top: 50px;
        margin-bottom: 50px;
    }
}