#projects-wrapper {
    align-self: center;
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 100px;
    margin-top: 100px;
    margin-bottom: 100px;
}