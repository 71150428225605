#about-wrapper {
    color: whitesmoke;
    line-height: 30px;
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 50px;
}

#about-wrapper h1 {
    margin-top: 50px;
}