#contact-wrapper {
    flex-grow: 1;
    color: #ECDBBA;
    display: flex;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 100px;
}

#contact-form {
    width: 350px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.form-group input, textarea {
    border: 3px solid #2D4263;
    border-radius: 7px;
    background-color: whitesmoke;
    padding: 5px;
    font-family: inherit;
    font-size: inherit;
    color: #191919;
}

.form-group input:focus, textarea:focus {
    outline: none;
}

.form-group textarea {
    min-height: 20px;
    resize: vertical;
}

#form-submit-button-container {
    display: flex;
}

#form-submit-button-container input {
    height: 35px;
    background-color: transparent;
    cursor: pointer;
    border: 1px solid #ECDBBA;
    outline: none;
    color: #ECDBBA;
}

@media (max-height: 700px) {
    #contact-wrapper {
        margin-top: 50px;
        margin-bottom: 50px;
    }
}