#project-card-wrapper {
    width: 335px;
    height: 500px;
    background-color: whitesmoke;
    border: 20px solid #2D4263;
    border-radius: 10px;
    outline: 3px dashed gray;
    outline-offset: 20px;
    display: flex;
    flex-direction: column;
    color: #191919;
}

#project-card-image-container {
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ECDBBA;
    border-bottom: 3px dashed #2D4263;
}

#project-card-link {
    text-decoration: none;
    color: #191919;
    flex: 1;
}

#project-card-header {
    line-height: 30px;
    padding: 20px;
}

#project-card-header:hover {
    text-decoration: underline;
}

#project-card-description {
    flex: 1;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 25px;
}

@media (max-width: 500px) {
    #project-card-wrapper {
        width: 251.25px;
        height: 375px;
        outline-offset: 22.5px;
    }

    #project-card-image {
        width: 140.625px;
        height: 140.625px;
    }

    #project-card-header {
        line-height: 22.5px;
        padding: 15px;
    }

    #project-card-description {
        padding-left: 15px;
        padding-right: 15px;
        line-height: 18.75px;
        font-size: 15px;
    }
}