#project-details-wrapper {
    color: whitesmoke;
    line-height: 30px;
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 50px;
}

#project-details-wrapper h1 {
    margin-top: 50px;
    border-bottom: 1px solid gray;
}

#project-details-wrapper h2 {
    text-decoration: underline;
    text-decoration-color: gray;
    text-decoration-thickness: 1px;
    text-underline-offset: 6px;
}

#project-details-wrapper a {
    color: #C84B31;
    text-decoration: none;
}