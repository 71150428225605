#skills-wrapper {
    width: 440px;
    height: 640px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

#skills-header {
    padding-top: 30px;
    color: #ECDBBA;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

#skills-tabber {
    margin: 0;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.skills-tab-item {
    height: 50px;
    display: flex;
}

.skills-tab-item-button {
    padding: 10px;
    color: #ECDBBA;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

#skills-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

@media (max-width: 500px) {
    #skills-wrapper {
        width: 330px;
        height: 480px;
        padding-bottom: 300px;
    }

    .skills-tab-item {
        height: 37.5px;
    }
}