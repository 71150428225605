#summary-card-wrapper {
    width: 400px;
    height: 600px;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    border: 20px solid #2D4263;
    border-radius: 10px;
}

#summary-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #2D4263;
}

#me-image {
    padding: 25px;
    border-radius: 200px;
}

#summary-text-container {
    padding: 15px;
    border-bottom: 1px solid #2D4263;
    line-height: 20px;
}

#summary-card-footer {
    flex-grow: 1;
    display: flex;
    align-items: center;
}

@media (max-width: 500px) {
    #summary-card-wrapper {
        width: 300px;
        height: 450px;
    }

    #summary-card-footer {
        height: 51px;
    }

    #me-image {
        width: 187.5px;
        height: 187.5px;
    }

    #summary-text-container {
        padding: 11.75px;
        line-height: 15px;
        font-size: 11.5px;
    }
}