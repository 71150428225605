@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');

body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

h1 {
    margin: 0;
}