#social-links-wrapper {
    padding: 15px;
}

#social-links {
    padding-left: 0;
    display: flex;
}

.social-link-item {
    width: 35px;
    display: flex;
    justify-content: center;
}

.social-link-item-link {
    text-decoration: none;
    color: #191919;
}