#app {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

#content {
    width: 100%;
    flex-grow: 1;
    background-color: #191919;
    display: flex;
}